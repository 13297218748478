






















import Vue from 'vue';
import { Precision, secondsToPrettyString } from '@/common/utils/time';
import moment from 'moment';

let interval: number | undefined;

export default Vue.extend({
  name: 'Timer',

  props: {
    /** ISO8601 timestamp of the start time */
    startDateTime: {
      type: String,
      required: false,
      default: null,
    },
    /** ISO8601 timestamp of the expiry time */
    expiryDateTime: {
      type: String,
      required: false,
      default: null,
    },
    /** default color for the timer */
    color: {
      type: String,
      default: 'primary',
    },
    /** color for the timer during warning time */
    warningColor: {
      type: String,
      required: false,
      default: 'warning',
    },
    /** when to show warningColor as seconds left in timer */
    warningTime: {
      type: Number,
      required: false,
      default: null,
    },
    /** color for the timer after expiration */
    expiredColor: {
      type: String,
      default: 'error',
    },
    /** text for the timer during warning time */
    warningText: {
      type: String,
      required: false,
      default: '',
    },
    /** text for the timer after expiration */
    expiredText: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /** elapsed parking time in seconds since startDateTime */
      elapsedTime: 0,
    };
  },
  computed: {
    /** maximum parking time in seconds since startDateTime */
    maxParkingTime(): number {
      return moment(this.expiryDateTime).diff(moment(this.startDateTime), 'seconds');
    },
    /** calculates the elapsed percentage (example: 15% -> 15) */
    elapsedPercentage(): number {
      return Math.max(Math.min(100, (this.elapsedTime / this.maxParkingTime) * 100), 0);
    },
    /** remaining time as a formatted timestamp */
    timeRemaining(): string {
      // Calculate remaining time in seconds
      const rem = Math.max(0, Math.floor(this.maxParkingTime - this.elapsedTime));

      // eslint-disable-next-line no-restricted-globals
      return isNaN(rem) ? '' : secondsToPrettyString(rem, Precision.Seconds);
    },
    /** remaining time as a seconds */
    timeRemainingSeconds(): number {
      // Calculate remaining time in seconds
      const rem = Math.max(0, Math.floor(this.maxParkingTime - this.elapsedTime));

      // eslint-disable-next-line no-restricted-globals
      return isNaN(rem) ? 0 : rem;
    },
    /** dynamic color for the progress bar and the text inside it */
    progressColor(): string {
      // eslint-disable-next-line no-nested-ternary
      return (this.elapsedPercentage >= 100)
        ? this.expiredColor
        : (this.warningTime && this.timeRemainingSeconds <= this.warningTime)
          ? this.warningColor
          : this.color;
    },
  },
  watch: {
    startDateTime() {
      this.updateElapsed();
    },
    expiryDateTime() {
      this.updateElapsed();
    },
  },
  mounted() {
    // Update elapsed time once per second
    this.updateElapsed();
    interval = window.setInterval(this.updateElapsed, 1000);
  },
  beforeDestroy() {
    // Remove updater
    window.clearInterval(interval);
  },
  methods: {
    /** Updates the value of elapsedTime */
    updateElapsed(): void {
      // Emit expired event when the timer hits zero
      // (but only when both startDateTime and expiryDateTime are given)
      if (this.startDateTime && this.expiryDateTime && this.maxParkingTime <= this.elapsedTime) {
        this.$emit('expired');
      }

      this.elapsedTime = moment().diff(moment(this.startDateTime), 'seconds');
    },
  },
});
